<template>
  <div class="message">
    <div class="w">
      <div class="cont">
        <div class="search">
          <div class="search-tabs">
            <!-- <div :class="['search-tab',nType == 0 ? 'active':'']" @click="changeNType(0)">公告状态</div> -->
            <div :class="['search-tab',nType == 1 ? 'active':'']" @click="changeNType(1)">公告标题</div>
          </div>
          <div class="flex-between">
            <div class="search-box">
              <el-input placeholder="请输入公告状态进行查询" v-model="yshClarificationParams.title" clearable>
              </el-input>
              <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
            </div>
            <div class="btn-group">
              <div :class="['btn',cType == 3? 'active':'']" @click="changeType(3)">变更公告</div>
              <div :class="['btn',cType == 1? 'active':'']" @click="changeType(1)">澄清公告</div>
            </div>
          </div>

          <img class="hr" src="@/assets/imgs/hr.png" />
          <div class="articles">
            <div class="li" v-for="(item,index) in yshClarificationList" :key="index">
              <div class="text">
                <div class="top-text">
                  <div class="title">{{item.title}}</div>
                  <div class="card_btn">
                  <el-button
                          type="primary"
                          size="small"
                          style="border-radius: 5px"
                          plain
                          @click="goDetail(item.id)"
                          class="btnhover fl"
                  >查看详情
                  </el-button>
                </div>
                </div>
                <div class="bottom-text">
                  <!-- <p v-html="item.content"></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="text-align:right;margin:40px 0">
          <el-pagination background layout="prev, pager, next, jumper" :total="totalElements">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      keywords: '',
      nType:1,
      cType:3,
      yshClarificationParams:{
        pageNum: 0,
        pageSize: 10,
        title: "",
        type: 3 ,// 3：变更公告 1、澄清公告
        zbNo: ""
      },
      yshClarificationList:[],
      totalElements:0
    }
  },
  created() {
    this.getYshClarificationList()
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        path: '/noticeDetail?id=' + id,
      })
    },
    search(){
      this.yshClarificationParams.pageNum = 0
      this.getYshClarificationList()
    },
    changeNType(type){
      this.nType = type
      this.yshClarificationParams.title = ''
    },
    changeType(type){
      this.cType = type
      this.yshClarificationParams.pageNum = 0
      this.yshClarificationParams.type = type
      this.getYshClarificationList()
    },
    async getYshClarificationList() {
      const res = await this.$axios.getYshClarificationList(this.yshClarificationParams)
      // console.log('getYshClarificationList:',res);
      this.yshClarificationList = res.data.content || []
      this.totalElements = res.data.totalElements || 0
    },
  }
}
</script>

<style lang='scss' scoped>
.flex-between {
  display: flex;
  justify-content: space-between;
}
.btn-group {
  display: flex;
  justify-content: flex-end;
  width: 516px;
  .btn {
    color: #9EA5B6;
    font-size: 14px;
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #DBDFE9;
    cursor: pointer;
    &.active {
      color: #0147EB;
      border-color: #0147EB;
    }
  }
}
.cont {
  .search-tabs {
    display: flex;
    .search-tab {
      padding: 9px 15px;
      font-size: 14px;
      font-weight: 700;
      color: #515a6e;
      &.active {
        color: #fff;
        background-color: #0055e2;
      }
    }
  }
  .search-box {
    flex: 1;
    display: flex;
    margin-bottom: 40px;
  }
  .hr {
    width: 100%;
    height: 4px;
  }
  .articles {
    .li {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;
      height: 135px;
      &:nth-child(2n) {
        background-color: #f3f5f8;
      }
      .text {
        flex: 1;
        padding-right: 40px;
      }
      .status {
        color: #315ccf;
      }
    }
    .top-text {
      padding-top: 7px;
      font-size: 20px;
      font-weight: 700;
      color: #181f2d;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .bottom-text {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #515a6e;
      gap: 20px;
    }
    .image {
      p {
        color: #315ccf;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
.news::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

/* 设置滚动条滑块的样式 */
.news::-webkit-scrollbar-thumb {
  background-color: #dbdfe9;
  border-radius: 2px;
}

/* 设置滚动条滑块悬停时的样式 */
.news::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* 设置滚动条轨道的样式 */
.news::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
<style lang='scss'>
.search-box {
  .el-input__inner {
    border-color: #0055e2;
    border-radius: 0;
  }
  .el-button {
    background-color: #0055e2;
    border-radius: 0;
    margin-left: -1px;
  }
}
</style>