<template>
  <Wrapper>
    <div class="service">

      <DetailBanner titleType='2' title=""></DetailBanner>

      <water-title Chinese="招采投标" English="CORPORATE BUSINESS"></water-title>

      <div class="service-icon">
        <div v-for="(item,index) in serviceIconArr" @click="()=>{actIndex=index}" :key="index"
          :class="['icon-box',{'act':actIndex===index}]">
          <img :src="item.path" :alt="item.name" />
          <div class="text">{{item.name}}</div>
        </div>
      </div>

      <!-- 招标信息 -->
      <Message v-if="actIndex===0" />
      <!-- 中标/结果公示 -->
      <Result v-if="actIndex===1" />
      <!-- 澄清/变更公告 -->
      <Change v-if="actIndex===2" />

    </div>
  </Wrapper>
</template>

<script>
import DetailBanner from '../sections/DetailBanner.vue'
import WaterTitle from '@/components/WaterTitle.vue'
import Message from './serviceComponents/Message.vue'
import Result from './serviceComponents/Result.vue'
import Change from './serviceComponents/Change.vue'
import Wrapper from '@/components/Wrapper.vue'

export default {
  mixins: [],
  components: {
    WaterTitle,
    DetailBanner,
    Change,
    Message,
    Wrapper,
    Result
  },
  data() {
    return {
      actIndex: 0,
      serviceIconArr: [
        { name: '招标信息', path: require('@/assets/imgs/service/a.png') },
        { name: '中标/结果公示', path: require('@/assets/imgs/service/b.png') },
        { name: '澄清/变更公告', path: require('@/assets/imgs/service/c.png') }
      ]
    }
  },
  created() {
    const activeTab = this.$route.query.activeTab
    if(activeTab) {
      this.actIndex = activeTab
    } else {
      this.actIndex = 0
    }
    
    this.scrollTop()
  },
  methods: {
    scrollTop() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        console.log(scrollTop)
      if (scrollTop === 0) {
        return false
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.service {
  padding: 50px 0px 0px 0px;
}
.service-icon {
  margin: 25px auto;
  display: flex;
  justify-content: center;
}
.icon-box {
  width: 228px;
  height: 156px;
  background: #ffffff;
  border: 1px solid #dbdfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.act {
  box-shadow: 0px 12px 30px 0px rgba(18, 55, 141, 0.2);
  border-bottom: 2px solid #0055e2;
}
.text {
  font-size: 20px;
  font-weight: bold;
  color: #515a6e;
  margin-top: 11px;
}
</style>
