<template>
  <div class="message">
    <div class="w">
      <div class="aside">
        <img class="title-img" src="@/assets/imgs/service/b-title.png">
        <div class="tabs">
          <div class="tab active">最新中标动态</div>
          <!-- <div class="tab">其他</div> -->
        </div>
        <ul class="news">
          <li v-for="item in news" :key="item.imid">
            <div class="top">
              <div class="title">{{item.cname}}</div>
              <div class="title">中标</div>
              <div class="tag">{{item.mname}}</div>
            </div>
            <div class="bottom">
              <div class="status">{{ item.uname }}</div>
              <div class="date">
                <img src="@/assets/imgs/date.png">
                <span>时间：{{item.zbDate}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="cont">
        <div class="search">
          <div class="search-tabs">
            <div class="search-tab active">中标标题</div>
            <!-- <div class="search-tab">中标标题</div> -->
          </div>
          <div class="search-box">
            <el-input placeholder="请输入中标状态进行查询" v-model="yshClarificationParams.title" clearable>
            </el-input>
            <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          </div>
          <img class="hr" src="@/assets/imgs/hr.png" />
          <div class="articles">
            <div class="li" v-for="(item,index) in yshClarificationList" :key="index">
              <div class="text">
                <div class="top-text">
                  <div class="title" v-html="item.title"></div>
                  <div class="card_btn">
                  <el-button
                          type="primary"
                          size="small"
                          style="border-radius: 5px"
                          plain
                          @click="goDetail(item.id)"
                          class="btnhover fl"
                  >查看详情
                  </el-button>
                </div>
                </div>
                <div class="bottom-text">
                  <p>招标状态:已中标</p>
                  <p>编号:{{item.zbNo}}</p>
                  <p>{{item.mname}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div style="text-align:right;margin:40px 0">
          <el-pagination background layout="prev, pager, next, jumper" :total="totalElements" @current-change="onChangePage">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      // 侧栏：最新中标状态
      news: [],
      keywords: '',
      yshClarificationParams:{
        pageNum: 0,
        pageSize: 10,
        title: "",
        type: 2 ,
        zbNo: ""
      },
      yshClarificationList:[],
      totalElements:0
    }
  },
  created() {
    this.getNews()
    this.getYshClarificationList()
  },
  methods: {
    // 侧栏：最新中标状态
    async getNews() {
      const res = await this.$axios.getInquiryUser(20)
      // console.log('getInquiryUser:',res);
      this.news = res.data || []
    },
    // 侧栏：最新中标状态
    async getYshClarificationList() {
      const res = await this.$axios.getYshClarificationList(this.yshClarificationParams)
      // console.log('getYshClarificationList:',res);
      this.yshClarificationList = res.data.content || []
      this.totalElements = res.data.totalElements || 0
    },
    onChangePage(pageNum) {
      this.yshClarificationParams.pageNum = pageNum - 1
      this.getYshClarificationList()
    },
    search(){
      this.yshClarificationParams.pageNum = 0
      this.getYshClarificationList()
    },
    goDetail(id) {
      this.$router.push({
        path: '/noticeDetail?id=' + id,
      })
    },
  }
}
</script>

<style lang='scss' scoped>
.message .w {
  display: grid;
  gap: 30px;
  grid-template-columns: 3fr 4fr;
}
.aside {
  .title-img {
    width: 100%;
  }
  .tabs {
    margin-top: 30px;
    display: flex;
    color: #9ea5b6;
    font-size: 20px;
    .tab {
      padding: 5px 18px;
      cursor: pointer;
      margin-right: 40px;
      text-decoration: underline;
      &.active {
        background-color: #315ccf;
        color: #fff;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .news {
    margin-top: 20px;
    height: 606px;
    overflow-y: scroll;
    li {
      padding: 25px 40px 25px 20px;
      background-color: #f3f5f8;
      &:nth-child(2n) {
        background-color: #fff;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      color: #181f2d;
      font-weight: 700;
      .tag {
        color: #315ccf;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #515a6e;
      margin-top: 25px;
      .date {
        display: flex;
        align-items: center;
      }
      .status {
        margin-right: 40px;
      }
    }
  }
}
.cont {
  .search-tabs {
    display: flex;
    .search-tab {
      padding: 9px 15px;
      font-size: 14px;
      font-weight: 700;
      color: #515a6e;
      &.active {
        color: #fff;
        background-color: #0055e2;
      }
    }
  }
  .search-box {
    display: flex;
    margin-bottom: 40px;
  }
  .hr {
    width: 100%;
    height: 4px;
  }
  .articles {
    .li {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;
      height: 135px;
      &:nth-child(2n) {
        background-color: #F3F5F8;
      }
      .text {
        flex: 1;
        padding-right: 40px;
      }
      .status {
        color: #315CCF;
      }
    }
    .top-text {
      padding-top: 7px;
      font-size: 20px;
      font-weight: 700;
      color: #181F2D;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .bottom-text {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #515A6E;
      gap: 20px
    }
    .image {
      p {
        color: #315CCF;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
.news::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

/* 设置滚动条滑块的样式 */
.news::-webkit-scrollbar-thumb {
  background-color: #dbdfe9;
  border-radius: 2px;
}

/* 设置滚动条滑块悬停时的样式 */
.news::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* 设置滚动条轨道的样式 */
.news::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
<style lang='scss'>
.search-box {
  .el-input__inner {
    border-color: #0055e2;
    border-radius: 0;
  }
  .el-button {
    background-color: #0055e2;
    border-radius: 0;
    margin-left: -1px;
  }
}
</style>