<template>
  <div class="message">
    <!-- 招标信息 -->
    <div class="w">
      <div class="aside">
        <img class="title-img" src="@/assets/imgs/service/a-title.png">
        <div class="tabs">
          <div :class="['tab', leftNewsType == 0? 'active':'']" @click="changeNewsType(0)">最新采购动态
          </div>
          <!-- <div :class="['tab', leftNewsType == 2? 'active':'']" @click="changeNewsType(1)">其他</div> -->
        </div>
        <ul class="news">
          <li v-for="item in news" :key="item.inquirymid">
            <div class="top">
              <div class="title">{{item.cname}}</div>
              <div class="title">采购</div>
              <div class="tag">{{item.mname}}</div>
            </div>
            <div class="bottom">
              <div class="status">到期</div>
              <div class="date">
                <img src="@/assets/imgs/date.png">
                <span>时间：{{item.deadline}}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="cont">
        <div class="search">
          <div class="search-tabs">
            <div class="search-tab" :class="activeQueryType===0?'active':''"
              @click="onTabQueryType(0)">采购品名</div>
            <div class="search-tab" :class="activeQueryType===1?'active':''"
              @click="onTabQueryType(1)">采购标题</div>
          </div>
          <div class="search-box">
            <el-input placeholder="请输入采购品名进行查询" v-model="keywords" clearable>
            </el-input>
            <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          </div>
          <img class="hr" src="@/assets/imgs/hr.png" />
          <div class="articles">
            <div class="li" v-for="(item,index) in rightList" :key="item.inquiryid">
              <div class="text">
                <div class="top-text">
                  <div class="title">{{item.cname}}</div>
                  <div class="status"
                    v-if="countdownValueArr[item.inquiryid] !== '已截止' && (item.bidStatus == 2 || item.bidStatus == 7)">
                    <div class="" style="display: flex;flex-direction: column; text-align: right;">
                      <div>
                        <el-button type="primary" size="small" style="border-radius: 5px" plain
                          @click="detailBtn(item.inquiryid)" class="btnhover fl">立即报价
                        </el-button>
                      </div>
                      <div class="headTxt" style="font-size: 12px; color: #999">
                        报价剩余：<span style="color: #999">{{
                          countdownValueArr[index]
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-else style="float: left; top: 20px">投标截止</div>
                </div>
                <div class="bottom-text">
                  <p>招标状态:{{item.bidStatus | BID_STATUS}}</p>
                  <p>编号:{{item.zbNo}}</p>
                  <p>采购品名:{{item.mname}}</p>
                </div>
              </div>

              <!--<div class="image" v-if="nums == index">
                <vue-qr
                        style="display: block; width: 150px; height: 150px"
                        class="card-con-right-img"
                        :text="qrurl + '?id=' + item.inquiryid"
                ></vue-qr>
                <div class="card-con-right-value">扫一扫立即报价</div>
              </div>-->


              <div class="image">
                <!-- <img class="qrcode" src="@/assets/imgs/code1.png" /> -->
                <vue-qr style="width:86px;height:86px" :text="`${qrurl}?id=${item.inquiryid}`"></vue-qr>
                <p>扫一扫立即报价</p>
              </div>
            </div>
          </div>
        </div>

        <div class="page-bar">
          <el-pagination background layout="prev, pager, next, jumper" :total="rightTotal"
            @current-change="onChangePage">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getUserInfo, logOff } from '@/api/modules'
import VueQr from 'vue-qr'
import { QR_URL } from '@/config/globalConfig'

export default {
  mixins: [],
  components: { VueQr },
  data() {
    return {
      // 侧栏：最新采购动态
      leftNewsType: 0, // 0:最新动态 1:其他
      news: [],
      activeQueryType: 0,
      keywords: '',
      queryForm: {
        mname: '', // 采购品名
        cname: '', // 采购标题
        pageIndex: 1,
        pageSize: 6
      },
      rightList: [],
      rightTotal: 0,
      countdownValueArr: [],
      userInfo: null,
      timer: [],
      qrurl: QR_URL
    }
  },
  computed: {
    // userInfo(params) {
    //   return this.$store.state.userInfo
    // },
  },
  created() {
    getUserInfo().then((res) => {
      console.log(res)
      this.userInfo = res.data || {}
      this.getNews()
      this.getRigthList()
    })
  },
  methods: {
    // 倒计时
    countdown(vals) {
      // for(var i=0; i< vals.length;i++){
      //     //定时器更新时间
      //     setInterval(()=>{
      //         this.transferTime(vals[i], i)
      //     }, 1000);
      // }
      console.log(vals, 'vals')
      let that = this
      vals.forEach((v, i) => {
        that.timer[i] = setInterval(() => {
          this.transferTime(v, i)
        }, 1000)
      })
    },
    transferTime(dateValue, index) {
      // console.log(dateValue,'firstAudit')
      // console.log(index)
      let nowTime = new Date()
      let targetTime = new Date(Date.parse(dateValue))
      // console.log(targetTime,'targetTime');
      //得到时间戳
      let timeStamp = targetTime - nowTime

      // this.tims = timeStamp
      //天数
      let day = parseInt(timeStamp / (1000 * 60 * 60 * 24))
      //小时
      let hour = parseInt((timeStamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      //分钟
      let minute = parseInt((timeStamp % (1000 * 60 * 60)) / (1000 * 60))
      //秒
      let second = parseInt((timeStamp % (1000 * 60)) / 1000)

      // var date = document.getElementById('info');
      //向info盒子中添加倒计时
      if (timeStamp < 0) {
        this.$set(this.countdownValueArr, index, '已截止')
      } else {
        this.$set(
          this.countdownValueArr,
          index,
          day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
        )
      }
      // console.log(this.tims, 'timeStamp')
      //this.countdownValueArr[index] =  day + '天' + hour + '小时' + minute + '分钟' + second + '秒'
    },
    detailBtn(inquiryId) {
      if (!this.userInfo.nickname) {
        this.$router.push('/login')
        // this.visibleLogin = true
        // console.log('yonh', this.userInfo.nickname)
      } else {
        if (this.userInfo.checkFlag == -1) {
          this.$message.warning('您还没有认证资质，请认证资质哦！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return
        } else if (this.userInfo.checkFlag == 0) {
          this.$message.warning('您的资质正在审核中，请等候！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return
        } else if (this.userInfo.checkFlag == 2) {
          this.$message.warning('您的资质认证未通过，请重新认证！')
          setTimeout(() => {
            this.$router.push({
              name: 'qualifications'
            })
          }, 1000)
          return
        }
        // this.visibleLogin = false
        this.$router.push({
          name: 'BidDetail',
          query: {
            id: inquiryId
          }
        })
      }
    },
    changeNewsType(type) {
      this.leftNewsType = type
    },
    // 侧栏：最新采购动态
    async getNews() {
      const res = await this.$axios.getYshInquiryMList(20)
      // console.log('getYshInquiryMList:',res);
      this.news = res.data || []
    },
    onTabQueryType(val) {
      if (this.activeQueryType === val) {
        return false
      }
      this.activeQueryType = val
      if (val == 0) {
        this.queryForm.mname = ''
      } else if (val == 1) {
        this.queryForm.cname = ''
      }
    },
    search() {
      if (this.keywords) {
        if (this.activeQueryType === 0) {
          this.queryForm.mname = this.keywords
          this.queryForm.cname = ''
        } else {
          this.queryForm.mname = ''
          this.queryForm.cname = this.keywords
        }
      } else {
        this.queryForm.mname = ''
        this.queryForm.cname = ''
      }
      this.queryForm.pageIndex = 1
      this.getRigthList()
    },
    clearCurTimer() {
      this.timer.forEach((v, i) => {
        clearInterval(v)
        console.log(i, '22')
      })
    },
    async getRigthList() {
      const model = {
        ...this.queryForm,
        type: 0,
        qstatus: 2,
        payment: 2
      }

      // const { data: res } = await this.$axios.post('/api/mall/yshInquiry/getInquiryList', model, {
      //   headers: { 'Content-Type': 'multipart/form-data' }
      // })
      const res = await this.$axios.getYshInquiryInquiryList(model)
      // console.log('getYshInquiryInquiryList:',res);
      this.clearCurTimer()
      this.rightList = res.data.content || []
      this.rightTotal = res.data.totalElements || 0
      let that = this
      let vals = []
      for (var i = 0; i < this.rightList.length; i++) {
        // console.log(datas[i]);
        if (this.rightList[i].pics != null) {
          let picArr = this.rightList[i].pics.split(',')
          let r = picArr.filter(function (s) {
            return s && s.trim()
          })
          this.rightList[i].pics = r
        } else {
          this.rightList[i].pics = []
        }
        vals.push(this.rightList[i].deadlineDate)
      }
      that.countdown(vals)
    },
    onChangePage(pageNum) {
      this.queryForm.pageIndex = pageNum
      this.getRigthList()
    }
  }
}
</script>

<style lang='scss' scoped>
.message .w {
  display: grid;
  gap: 30px;
  grid-template-columns: 3fr 4fr;
}
.aside {
  .title-img {
    width: 100%;
  }
  .tabs {
    margin-top: 30px;
    display: flex;
    color: #9ea5b6;
    font-size: 20px;
    .tab {
      padding: 5px 18px;
      cursor: pointer;
      margin-right: 40px;
      text-decoration: underline;
      &.active {
        background-color: #315ccf;
        color: #fff;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .news {
    margin-top: 20px;
    height: 606px;
    overflow-y: scroll;
    li {
      padding: 25px 40px 25px 20px;
      background-color: #f3f5f8;
      &:nth-child(2n) {
        background-color: #fff;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      color: #181f2d;
      font-weight: 700;
      .tag {
        color: #315ccf;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #515a6e;
      margin-top: 25px;
      .date {
        display: flex;
        align-items: center;
      }
      .status {
        margin-right: 40px;
      }
    }
  }
}
.cont {
  .search-tabs {
    display: flex;
    .search-tab {
      padding: 9px 15px;
      font-size: 14px;
      font-weight: 700;
      color: #515a6e;
      cursor: pointer;
      &.active {
        color: #fff;
        background-color: #0055e2;
      }
    }
  }
  .search-box {
    display: flex;
    margin-bottom: 40px;
  }
  .hr {
    width: 100%;
    height: 4px;
  }
  .articles {
    .li {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0;
      height: 135px;
      &:nth-child(2n) {
        background-color: #f3f5f8;
      }
      .text {
        flex: 1;
        padding-right: 40px;
      }
      .status {
        color: #315ccf;
        .btnhover {
          width: 100px;
          float: right;
        }
        .headTxt {
          margin-top: 5px;
        }
      }
    }
    .top-text {
      padding-top: 7px;
      font-size: 18px;
      font-weight: 700;
      color: #181f2d;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .bottom-text {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #515a6e;
      gap: 20px;
    }
    .image {
      p {
        color: #315ccf;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
.news::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

/* 设置滚动条滑块的样式 */
.news::-webkit-scrollbar-thumb {
  background-color: #dbdfe9;
  border-radius: 2px;
}

/* 设置滚动条滑块悬停时的样式 */
.news::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* 设置滚动条轨道的样式 */
.news::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
<style lang='scss'>
.search-box {
  .el-input__inner {
    border-color: #0055e2;
    border-radius: 0;
  }
  .el-button {
    background-color: #0055e2;
    border-radius: 0;
    margin-left: -1px;
  }
}
</style>